<template>
  <div class="nft container">
    <div class="title">{{ $t('news.order')}} ID:{{this.$route.query.id}}</div>
    <div class="my_invest">
      <div class="my_item" v-for="item in message" :key="item.id">

        <div >
          <span style="margin-top: 5px" >{{ $t('news.period')}}: {{item.period}}</span>
        </div>
        <span style="margin-top: 5px" v-if="item.type==1">{{ $t('news.type')}}: {{ $t('news.shares')}}</span>
        <span style="margin-top: 5px" v-else-if="item.type==2">{{ $t('news.type')}}: {{ $t('news.split')}}</span>
        <div v-if="item.type==2">
          <span style="margin-top: 5px" >{{ $t('news.split')}}{{ $t('news.number')}}: {{item.amount}}</span>
        </div>
        <div style="font-size: 0.2rem">
          <span style="color: #d23e61">
            <span style="color: #ffffff"> {{ $t('news.time')}} ：{{dateFromt(item.time)}}
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


import {get} from "@/utlis/axios";
import dayjs from "dayjs";
export default {
  data() {
    return {
        message:[],
    }
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
    clearInterval(this.initTimer)


  },
  methods: {
    dateFromt(times) {
      return dayjs(times).format("YYYY-MM-DD HH:mm:ss");
    },
    async init() {
      get('/myInvestDetail?id='+ this.$route.query.id,{}).then(res => {
        this.message =res;
      })
    },
    async getData() {

    },


  }
}
</script>

<style lang="scss" scoped>
.nft {
  padding: 1.4rem 0.44rem 0.4rem;
  .title {
    width: 5.74rem;
    height: 1.66rem;
    line-height: 1.28rem;
    font-size: 0.36rem;
    text-align: center;
    font-weight: bold;
    margin: 0 auto;
    background: url("../../assets/img/web-title-bg.png") left top / 100% 100%
    no-repeat;
  }
  .my_invest {
    display: flex;
    flex-direction: column;
    width: 100%;

    .my_item {
      display: flex;
      flex-direction: column;
      background: url(../../assets/img/invest-item-bg.png) left top / 100% 100% no-repeat;
      padding: .45rem .45rem;
      margin-bottom: .4rem;

      >div,
      header {
        display: flex;
        justify-content: space-between;
      }

      header {
        align-items: center;
        margin-bottom: .5rem;

        h4 {
          width: 2.48rem;
          text-align: center;
          line-height: .66rem;
          font-size: .3rem;
          font-weight: bold;
          height: .66rem;
          font-weight: normal;
          background: url(../../assets/img/invest-item-head.png) left top / 100% 100% no-repeat;
        }
      }

      >div {
        &:last-child {
          font-size: .3rem;
          margin-top: .1rem;
        }

        >div {
          &:nth-of-type(1) {
            width: 65%;
          }

          &:nth-of-type(2) {
            width: 35%;
          }

          >span {
            margin-left: .5rem;
            font-size: .22rem;
            color: #45BBF3;
          }
        }
      }

    }
  }

}
</style>
